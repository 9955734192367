jQuery(document).ready(function ($) {

    consoleMessage: {
        console.log('%cCreated by %cTWK%cwww.thewebkitchen.co.uk', 'background: #13212E; color: #FFFFFF; padding: 5px 0px 5px 10px; margin: 25px 0px;', 'background: #13212E; color: #05E5C8; padding: 5px 10px 5px 0px; font-weight: bold;', 'background: #FFFFFF; padding: 5px 10px;');
	}

	modernizer: {
        if (!Modernizr.objectfit) {
            $('.object-fit').each(function () {
                var $container = $(this),
                    imgUrl = $container.find('img').prop('src');
                if (imgUrl) {
                    $container
                        .css('backgroundImage', 'url(' + imgUrl + ')')
                        .addClass('not-compatible');
                }
            });
        }
	}

	fixedheader: {
		$(function () {
			$(window).scroll(function () {
				if ($(window).scrollTop() >= 150) {
					$('.header').addClass('fixed-header');
				}
				if ($(window).scrollTop() >= 160) {
					$('.header').addClass("transform-none");
				}
				else {
					$('.header').removeClass('fixed-header');
					$('.header').removeClass("transform-none");
				}
			});
		});
	}

	mobileMenu: {
		$('.mobile-menu__hamburger').click(function() {
			var menuParent = $(this).closest('.mobile-menu');
			if(menuParent.hasClass('menu-open')) {
				menuParent.removeClass('menu-open');
			} else {
				menuParent.addClass('menu-open');
			}
			
		})
		$(window).click(function(e) {
			
			if($('.mobile-menu').hasClass('menu-open') && ! e.target.closest('.mobile-menu'))  {
				$('.mobile-menu').removeClass('menu-open');
			}
			
			
			
		})
	}
	search: {
		$('.search-icon').on('click', function (e) {
			$('.searchform').toggleClass('search-open');
			$(this).toggleClass('submit-zindex');
			$('.select-lang').removeClass('transform-height');
			$('#menu-main-menu-top, .lang_dropdown').fadeOut();
			$("#s").focus();
			setTimeout(function () {
				$('.close-search').fadeIn();
			}, 300);
		});
		$('.close-search').on('click', function (e) {
			$(this).fadeOut();
			$('.searchform').removeClass('search-open');
			$('.search-icon').removeClass('submit-zindex');
			$('#menu-main-menu-top, .lang_dropdown').fadeIn();
		});
	}

	menu: {
		// Open submenu on click
		$( '.top-nav .menu-item-has-children > a' ).on('click', function(e){
			e.preventDefault();

			$('.menu-item-has-children').removeClass('sub-menu-open'); // reset
			$('.sub-menu-wrap').removeClass('sub-menu-open');          // reset

			$(this).closest('.menu-item-has-children').toggleClass('sub-menu-open');
			$(this).next('.sub-menu-wrap').toggleClass('sub-menu-open');
		});
		// Open menu with tabs - accessibility
		$( '.top-nav .sub-menu-wrap a' ).on('focusin', function(){
			$(this).closest('.menu-item-has-children').addClass('sub-menu-open');
			$(this).closest('.sub-menu-wrap').addClass('sub-menu-open');

			console.log($(this));
		});
		$( '.top-nav .sub-menu-wrap a' ).on('focusout', function(){
			$(this).closest('.menu-item-has-children').removeClass('sub-menu-open');
			$(this).closest('.sub-menu-wrap').removeClass('sub-menu-open');
		});
		// Close on click outside.
		$(document).on('click', function(e){
			if ( ! $(e.target).parent().hasClass('menu-item') && $('.sub-menu-wrap').has(e.target).length === 0 ){
				$('.menu-item-has-children').removeClass('sub-menu-open');
				$('.sub-menu-wrap').removeClass('sub-menu-open');
			}
		});
	}

	anchorlinks: {
		$(document).on('click', '.scroll-to', function (event) {
			event.preventDefault();

			$('html, body').animate({
				scrollTop: $($.attr(this, 'href')).offset().top
			}, 500);
		});
	}

	externallinks: {
		$('a[href^="mailto:"]').each(function () {
			$(this).addClass('email-link');
		});	 
		
		$('a:not(.email-link):not([class^="magnific-"])').each(function () {
			var a = new RegExp('/' + window.location.host + '/');
			
			if (!a.test(this.href)) {
				$(this).click(function (event) {
				event.preventDefault();
				window.open(this.href, '_blank');
				});
			}
		});
		pdfs: {
			// Open PDFs in new window
			$(function () {
				$('a[href$=".pdf"]').prop('target', '_blank');
			});
		}
	}

	slider: {
		$('.full-width-slider').slick({
			dots: true,
            arrows: false,
            fade: true,
			autoplay: true,
			autoplaySpeed: 3500,
			speed: 1200,
		});
		$('.js-vessel-slider').slick({
			dots: false,
            arrows: true,
            fade: false,
			autoplay: false,
			infinite: false,
			autoplaySpeed: 3500,
			speed: 1200,
			slidesToShow: 2,
			variableWidth: true,
			responsive: [{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
				
				}
			}]
		});

		$('.banner-image-slider').slick({
			dots: true,
            arrows: false,
            fade: false,
			variableWidth: true,
			// centerMode: true,

			autoplay: false,
			outerEdgeLimit: true,
			infinite: false,
			responsive: [{
				breakpoint: 630,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					variableWidth: false,
					// centerPadding: '60px',
					// 			centerMode: true,


				}
			}]



			
		});



		/**
		 * Gallery slider with thumbnails navigation.
		 */
		$( '.gallery-slider' ).each(function(index){
			var status            = $(this).find('.gallery-slider__full-nav .pages');
			var slickElement      = $(this).find('.gallery-slider__full');
			var slickElementThumb = $(this).find('.gallery-slider__thumb');

			slickElement.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
				//currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
				var i = (currentSlide ? currentSlide : 0) + 1;
				status.text(i + ' of ' + slick.slideCount);
			});

			slickElement.slick({
				dots: false,
				arrows: true,
				fade: true,
				asNavFor: $(this).find('.gallery-slider__thumb'),
				appendArrows: $(this).find('.gallery-slider__full-nav'),
			});

			slickElementThumb.slick({
				dots: false,
				arrows: true,
				slidesToShow: 4,
				// centerMode: true,
				focusOnSelect: true,
				asNavFor: $(this).find('.gallery-slider__full'),
			});
		});
	}
	
	sliderBackground: {
		var colourArray = ['5AB000']
		var totalSlides = $('.js-vessel-slider').attr('data-total-slides');
		$('.js-vessels-background').each( function () {
			var index = parseInt($(this).parent().parent().attr('data-slick-index')) + 1;
			
			var color1 = '479EDC';
			var color2 = '5AB000';
			var ratio = index/totalSlides;
			var hex = function(x) {
				x = x.toString(16);
				return (x.length == 1) ? '0' + x : x;
			};
			
			var r = Math.ceil(parseInt(color1.substring(0,2), 16) * ratio + parseInt(color2.substring(0,2), 16) * (1-ratio));
			var g = Math.ceil(parseInt(color1.substring(2,4), 16) * ratio + parseInt(color2.substring(2,4), 16) * (1-ratio));
			var b = Math.ceil(parseInt(color1.substring(4,6), 16) * ratio + parseInt(color2.substring(4,6), 16) * (1-ratio));
			
			var middle = hex(r) + hex(g) + hex(b);
			colourArray.push( middle );		
			
			var styleUpdate = 'linear-gradient(124.59deg, #' + colourArray[index - 1] + ' 0%, #' + colourArray[index] + ' 100%)';
			
			$(this).css('background', styleUpdate)							
		});
		


		
	}
	magnific: {
		// IFRAME
		$('.magnific-video').magnificPopup({
			type: 'iframe',
			mainClass: 'mfp-video-wrapper',
			removalDelay: 160,
			preloader: false,
			fixedContentPos: false,

			iframe: {
				markup: '<div class="mfp-iframe-scaler">' +
					'<div class="mfp-close"></div>' +
					'<iframe class="mfp-iframe" frameborder="0" allow="autoplay"></iframe>' +
					'</div>',
				patterns: {
					youtu: {
						index: 'youtu.be',
						id: function( url ) {
						
							// Capture everything after the hostname, excluding possible querystrings.
							var m = url.match( /^.+youtu.be\/([^?]+)/ );
					
							if ( null !== m ) {
								return m[1];
							}
					
							return null;
				
						},
						// Use the captured video ID in an embed URL. 
						// Add/remove querystrings as desired.
						src: '//www.youtube.com/embed/%id%?autoplay=1&rel=0'
					},
					youtube: {
						index: 'youtube.com/',
						id: 'v=',
						src: 'https://www.youtube.com/embed/%id%?autoplay=1'
					}
				}
			}
		});


		// INLINE
		$('.magnific-inline-popup').magnificPopup({
            type: 'inline',

			fixedContentPos: false,
			fixedBgPos: true,

			overflowY: 'auto',

			closeBtnInside: true,
			preloader: false,
			
			midClick: true,
			removalDelay: 300,
			mainClass: 'my-mfp-zoom-in'
        });


		// GALLERY SINGLE IMAGE
		$('.magnific-gallery-single-image').magnificPopup({
			type: 'image',
			closeOnContentClick: true,
			mainClass: 'mfp-img-single',
			image: {
				verticalFit: true,
				titleSrc: function titleSrc(item) {
				return item.el.attr('title');
				}
			},
			gallery: {
				enabled: false
			}
		});          

        // GALLERY IMAGE
        $('.magnific-gallery-image').magnificPopup({
            type: 'image',
            closeOnContentClick: true,
            mainClass: 'mfp-img-mobile',
            image: {
                verticalFit: true,
                titleSrc: function(item) {
                    return item.el.attr('title');
                }
            },
            gallery:{
                enabled:true
            }
		});
	}

	accordion: {
		if ($('.block--accordion').length) {

			$( '.accordion__content--wrapper' ).css('display', 'none'); // All items closed.
	
			$('.block--accordion').find('.js-accordion-trigger').on('click', function() {

				// Close only the items in this accordion.
				$(this).parent().siblings().find('.js-accordion-trigger').next().slideUp('fast'); // reset
				$(this).parent().siblings().find('.js-accordion-trigger').removeClass('open');    // reset

				// Close items in all accordions on the page.
				//$('.js-accordion-trigger').not(this).next().slideUp('fast'); // reset
				//$('.js-accordion-trigger').not(this).removeClass('open');    // reset
				
				if ($(this).hasClass('open')){
					$(this).next().slideUp('fast');
					$(this).removeClass('open');
				} else {
					$(this).next().slideDown('fast');
					$(this).addClass('open');
				}
				
			});
		}
	}

	tabs: {
		var tabTitles = document.querySelectorAll('.tab__header');

		for(var i = 0; i < tabTitles.length; i++) {
			tabTitles[i].addEventListener('click', function() {
				console.log('tab clicked');
			});
			
		}
	}
		

		// Side bar active and show the right content on the right
		// for (var i = 0; i < tabTitle.length; i++) {
		// 	tabTitle[i].addEventListener('click', function () {

		// 		console.log("Data procedure clicked");

		// 		tabTitle.forEach(item => {
		// 			item.classList.remove('active');
		// 		});
		// 		tabTitle[i].classList.remove('active');
		// 		var dataProcedureId = this.getAttribute('data-procedure');

		// 		for (var x = 0; x < tabInfo.length; x++) {
		// 			if (tabInfo[x].getAttribute('data-content-info') == dataProcedureId) {             
		// 				tabInfo[x].classList.add('active');
		// 			} else {
		// 				tabInfo[x].classList.remove('active');
		// 			}
		// 		}

		// 		document.querySelector('[data-procedure="' + dataProcedureId + '"]').classList.add('active');
		// 	});
		// }

	/* tabs: {
		const tabs = document.querySelectorAll('[role="tab"]');
		const tabList = document.querySelector('[role="tablist"]');

		if ( tabs ) {

			// Add a click event handler to each tab
			tabs.forEach(tab => {
				tab.addEventListener("click", changeTabs);
			});

			// Enable arrow navigation between tabs in the tab list
			let tabFocus = 0;

			if ( tabList ) {
				tabList.addEventListener("keydown", e => {
					// Move right
					if (e.keyCode === 39 || e.keyCode === 37) {
					tabs[tabFocus].setAttribute("tabindex", -1);
					if (e.keyCode === 39) {
						tabFocus++;
						// If we're at the end, go to the start
						if (tabFocus >= tabs.length) {
						tabFocus = 0;
						}
						// Move left
					} else if (e.keyCode === 37) {
						tabFocus--;
						// If we're at the start, move to the end
						if (tabFocus < 0) {
						tabFocus = tabs.length - 1;
						}
					}
	
					tabs[tabFocus].setAttribute("tabindex", 0);
					tabs[tabFocus].focus();
					}
				});
			}

			function changeTabs(e) {
				console.info('tabs clicked');
				const target = e.target;
				const parent = target.parentNode;
				const grandparent = parent.closest('.tabs');


					console.info(grandparent);
				// Remove all current selected tabs
				parent
					.querySelectorAll('[aria-selected="true"]')
					.forEach(t => t.setAttribute("aria-selected", false));

				// Set this tab as selected
				target.setAttribute("aria-selected", true);

				// Hide all tab panels
				grandparent
					.querySelectorAll('[role="tabpanel"]')
					.forEach(p => p.setAttribute("hidden", true));

				// Show the selected panel
				grandparent.parentNode
					.querySelector(`#${target.getAttribute("aria-controls")}`)
					.removeAttribute("hidden");
			}

		}
	} */

	loadingAnimations: {
		$.fn.isOnScreen = function () {
			var win = $(window);
			var viewport = {
				top: win.scrollTop()
			};
			viewport.bottom = viewport.top + win.height() - 80;
		
			var bounds = this.offset();
			bounds.bottom = bounds.top + this.outerHeight();
		
			return (!(viewport.bottom < bounds.top || viewport.top > bounds.bottom));
		};

		// First load Animation
		$('.off-screen').each(function (index) {
			if ($(this).isOnScreen()) {
				$(this).removeClass('off-screen--hide');
			}
		});

		// Animation on scroll
		$(window).scroll(function () {
			$('.off-screen').each(function (index) {
				if ($(this).isOnScreen()) {
					$(this).removeClass('off-screen--hide');
				}
			});
		});
	}
});